import { serverRuntimeConfig } from "config";

import axios from "axios";

import { publicRuntimeConfig } from "app-config";

import { ValidationSourceResult } from "pages/api/validatesource";

import {
  ApiLimits,
  BaseProject,
  BillingRecord,
  DefaultSources,
  Maybe,
  Processing,
  ProcessingPayload,
  ProcessingStatus,
  ProcessingUpdate,
  Project,
  Schema,
  ShareProjectPayload,
  SortBy,
  SortOrder,
  TeamMember,
  TeamStatus,
  TMUserProfile,
  TopUpPayload,
  TopUpUrl,
  UserInfo,
} from "types";

import { ProcessingAoi } from "./../../../types/processing";
import { IBaseProject } from "./../../../types/project";

const WHITEMAPS_URL = publicRuntimeConfig.WHITEMAPS_URL;

export async function fetcher<T = any>(url: string): Promise<T> {
  return axios.get(`${WHITEMAPS_URL}/rest${url}`).then((res) => res.data);
}

/* USER SETTINGS */

export const updateUserMetaData = (data: TMUserProfile) => {
  return axios.put("/api/user/update", data);
};

export const fetchProfile = async (): Promise<TMUserProfile> => {
  const response = await axios.get<TMUserProfile>("/api/user/profile");
  return response.data;
};

export const fetchUserStatus = () => fetcher<TeamStatus>("/user/status");

export const fetchTeamMembers = (teamId: string | undefined) =>
  fetcher<TeamMember[]>(`/team/${teamId}/member`);

export type AddTeamMemberPayload = {
  email: string;
  teamId: string;
  activeUntil?: null | any;
  role?: string;
};

export const addTeamMember = (payload: AddTeamMemberPayload) => {
  return axios.post(
    `${WHITEMAPS_URL}/rest/team/${payload.teamId}/member/${payload.email}`,
    {
      role: "MEMBER",
    }
  );
};

export type RemoveTeamMemberPayload = {
  email: string;
  teamId: string;
};
export const removeTeamMember = (payload: RemoveTeamMemberPayload) => {
  return axios.delete(
    `${WHITEMAPS_URL}/rest/team/${payload.teamId}/member/${payload.email}`
  );
};

export type UpdateTeamMemberPayload = {
  teamId: string;
  email: string;
  creditsLimit?: number;
  /**
   * in square meters
   */
  areaLimit?: number;
  limit?: number;
  /**
   * date string
   */
  activeUntil?: string;
};
export const updateTeamMember = (payload: UpdateTeamMemberPayload) => {
  const limit = payload.areaLimit
    ? { areaLimit: payload.areaLimit }
    : { creditsLimit: payload.creditsLimit };

  return axios.put(
    `${WHITEMAPS_URL}/rest/team/${payload.teamId}/member/${payload.email}`,
    { ...limit }
  );
};

export const shareProject = (payload: ShareProjectPayload) => {
  return axios.post(`${WHITEMAPS_URL}/rest/projects/share`, {
    ...payload,
  });
};

export const unshareProject = (
  payload: Pick<ShareProjectPayload, "projectId" | "userId">
) => {
  return axios.post(`${WHITEMAPS_URL}/rest/projects/unshare`, {
    ...payload,
  });
};

export const craeteTeam = (payload: any) => {
  return axios.post(`${WHITEMAPS_URL}/rest/create/team`, payload);
};

export const fetchUserInfo = () => fetcher<UserInfo>("/user-info");

// export const fetchSchema = (filters: SchemaFilters = {}) => {
//   // @ts-expect-error
//   const qs = new URLSearchParams(filters).toString();
//   return fetcher<Schema>(`/schema?${qs}`);
// };

export const fetchDefaultSources = () =>
  fetcher<DefaultSources>("/default-sources");

export const fetchApiStats = () => fetcher<ApiLimits>("/apistats");
export const fetchApiToken = () => fetcher<string>("/users/getapitoken");

type TBillingReportParams = {
  dateFrom?: string;
  dateTo?: string;
  emails?: string;
  statuses?: ProcessingStatus[];
  terms?: string;
  limit?: number;
  offset?: number;
  // sortOrder: ProcessingSortOrder;
  sortBy?: SortBy;
};

export const fetchBillingReport = (filters: Maybe<TBillingReportParams>) => {
  return axios.post<BillingRecord[]>(
    `${WHITEMAPS_URL}/rest/processings/stats?type=JSON`,
    filters
  );
};

export const getPaymentUrl = (payload: TopUpPayload) => {
  return axios.post<TopUpUrl>(`${WHITEMAPS_URL}/rest/users/topup`, {
    ...payload,
    paymentProvider: serverRuntimeConfig.PAYMENT_PROVIDER,
  });
};

export const fetchTileURLFromCOG = async (cogURL: string): Promise<string> => {
  // const { tiles } = await fetcher<{ tiles: string[] }>(cogURL);

  const { tiles } = await axios.get(cogURL).then((res) => res.data);

  return tiles[0];
};

/* PROJECTS */
type TPaginatedProjectQS = {
  offset?: number;
  limit?: number;
  filter?: string;
  sortBy?: SortBy;
  sortOrder?: SortOrder;
};

export const fetchProjects = async (
  qs: TPaginatedProjectQS = {}
): Promise<IBaseProject> => {
  const response = await axios.post<IBaseProject>(
    `${WHITEMAPS_URL}/rest/projects/page`,
    qs
  );
  return response.data;
};
export const fetchProjectById = (id: string) =>
  fetcher<Project>(`/projects/${id}`);

export const deleteProject = (id: string) => {
  return axios.delete(`${WHITEMAPS_URL}/rest/projects/${id}`);
};

export const updateProject = (id: string, data: BaseProject) => {
  return axios.put<Project>(`${WHITEMAPS_URL}/rest/projects/${id}`, data);
};

export const createProject = (data: BaseProject) => {
  return axios.post<Project>(`${WHITEMAPS_URL}/rest/projects`, data);
};

export const linkWorkflowDef = async (options: {
  projectId: string;
  workflowDefIds: string[];
}) => {
  return await Promise.allSettled(
    options.workflowDefIds.map((id) =>
      axios.post(
        `${WHITEMAPS_URL}/rest/projects/${options.projectId}/models/${id}`
      )
    )
  );
};

export const unlinkWorkflowDef = async (options: {
  projectId: string;
  workflowDefIds: string[];
}) => {
  return await Promise.allSettled(
    options.workflowDefIds.map((id) =>
      axios.delete(
        `${WHITEMAPS_URL}/rest/projects/${options.projectId}/models/${id}`
      )
    )
  );
};
/* PROCESSING */

export const fetchProcessingsById = (id: string) =>
  fetcher<Processing[]>(`/projects/${id}/processings`);

export const fetchProcessingAoi = (id: string) => {
  return fetcher<ProcessingAoi[]>(`/processings/${id}/aois`);
};

export const fetchProcessing = (processingId: string) => {
  return fetcher<Processing>(`/processings/${processingId}`);
};

export function getRasterUrl(file: any) {
  const formdata = new FormData();
  formdata.append("file", file);

  // const formData = new FormData();
  // const { raster, ...payload } = data;
  // if (!raster) throw Error("Raster not found"); // for typescript

  // Object.keys(payload).forEach((key) => {
  //   const data = payload[key as keyof typeof payload];
  //   const value = typeof data === "string" ? data : JSON.stringify(data);
  //   formData.append(key, value);
  // });

  // if (raster.file) {
  //   formData.append("raster.file", raster.file);
  // }

  // formData.append("file.type", raster.type);

  return axios.post<any>(`${WHITEMAPS_URL}/rest/rasters`, formdata);
}

export const createProcessing = async (payload: ProcessingPayload) => {
  const newPayload = payload;
  if (payload.file) {
    const { data } = await getRasterUrl(payload.file);
    newPayload.params = {
      ...payload.params,
      url: data.url,
      source_type: "local",
    };
  }

  return axios.post<any>(`${WHITEMAPS_URL}/rest/processings`, {
    ...newPayload,
    file: undefined,
  });
};

export function calculateProcessingCost(data: ProcessingPayload) {
  return axios.post(`${WHITEMAPS_URL}/rest/processing/cost`, data);
}

export function rateProcessing(id: string, rating: number) {
  return axios.put<any>(`${WHITEMAPS_URL}/rest/processings/${id}/rate`, {
    rating,
  });
}

export function updateProcessing(id: string, data: ProcessingUpdate) {
  return axios.put<Processing>(`${WHITEMAPS_URL}/rest/processings/${id}`, data);
}

export function restartProcessing(id: string) {
  return axios.post<any>(`${WHITEMAPS_URL}/rest/processings/${id}/restart`);
}

export function deleteProcessing(id: string) {
  return axios.delete(`${WHITEMAPS_URL}/rest/processings/${id}`);
}

export function sendEmail(userId: string) {
  return axios.post<{ status: "pending" }>("/auth/send-email", {
    userId,
  });
}

/* OTHER */

export function validateSource(data: ProcessingPayload) {
  return axios.post<ValidationSourceResult>("/validatesource", data);
}

export type TGetAllowedZoom = Pick<ProcessingPayload, "aoi" | "source"> &
  Pick<Schema, "maxZoomOfAll" | "minZoomOfAll">;

export async function getAllowedZoom(params: TGetAllowedZoom) {
  const { data } = await axios.post<Record<number, boolean>>("/zoom", params);
  return data;
}
